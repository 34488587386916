/*
* Slideshow Helper
* ------------
* Extends Owl Carousel
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: Owl Carousel
*/


var slideshow = {
	init: function(){

		if ($('.js-slideshow').length > 0) {

			$('.js-slideshow')
				.each(

					function(index){

						//
						// Variables
						//

							var slideshowObject = $(this),
								slideshowSpeed = 550,
								slideshowActiveCount = 0;

						//
						// Options
						//

							var slideshowOptions,
								slideshowOptTabs = slideshowObject.data('slideshow-tabs'),
								slideshowOptAuto = slideshowObject.data('slideshow-auto'),
								slideshowOptType = slideshowObject.data('slideshow-type');

						//
						// Add Required Owl Class
						//

							slideshowObject.addClass('owl-carousel');

						//
						// Options
						//

							if (slideshowOptType === 'default') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 4,
										nav: false,
										dots: false,
										loop: false,
									}

							}
							else if (slideshowOptType == 'hero-slideshow') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 1,
										nav: false,
										dots: true,
										margin: 1,
										loop: true,
										animateOut: 'fadeOut',
										mouseDrag: false,
										touchDrag: false
									}

							}
							else if (slideshowOptType == 'product-interior-slideshow') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 1,
										nav: false,
										dots: false,
										margin: 1,
									}

							}
							else if (slideshowOptType == 'product-item-carousel') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 4,
										nav: true,
										navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
										dots: false,
										loop: false,
										autoHeight: true,
										responsive: {
											0: {
												items: 1,
												margin: 20,
												loop: true,
											},
											600: {
												items: 2,
												margin: 10,
												loop: true,
											},
											900: {
												items: 3,
												margin: 0,
												loop: true,
											},
											1100: {
												items: 4,
												margin: 0,
												loop: false,
											},
										}
									}

							}
							else if (slideshowOptType == 'logo-item-carousel') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 4,
										nav: true,
										navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
										dots: false,
										loop: false,
										autoHeight: true,
										responsive: {
											0: {
												items: 2,
												margin: 10,
												loop: true,
											},
											600: {
												items: 3,
												margin: 5,
												loop: true,
											},
											900: {
												items: 4,
												margin: 0,
												loop: false,
											}
										}
									}

							}
							else if (slideshowOptType == 'logo-item-carousel') {

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 4,
										nav: true,
										navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
										dots: false,
										loop: true,
										autoplay: true
									}

							}
							else {

								// Default

									slideshowOptions = {
										smartSpeed: slideshowSpeed,
										items: 1,
										nav: false,
										navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
										dots: false,
										loop: false,
										autoHeight: true
									}

							}

						//
						// Create
						//

							var slideshowObj = slideshowObject.owlCarousel(slideshowOptions),
								slideshowPaginationObj = $('[data-slideshow-pagination=' + slideshowOptType + ']'),
								slideshowNext = $('[data-slideshow-next=' + slideshowOptType + ']'),
								slideshowPrev = $('[data-slideshow-prev=' + slideshowOptType + ']');

							var	slideshowItems = $(this).find('.owl-item').length,
								slideshowClonesItems = $(this).find('.owl-item.cloned').length / 2,
								slideItemCount = slideshowItems - (slideshowClonesItems * 2);


						//
						// Init Callback
						//

							// Hack around to make images work with autoHeight

							setTimeout(
								function() {
									slideshowObj.data('owlCarousel').updateVars();
									slideshowObj.trigger('next.owl.carousel');
									slideshowObj.trigger('prev.owl.carousel');
								},
								1000
							);

						//
						// On Resize
						//

							// Hack around to make images work with autoHeight

							slideshowObject
								.on(
									'resized.owl.carousel',
									function(event) {

										slideshowObj.data('owlCarousel').updateVars();
										slideshowObj.trigger('next.owl.carousel');
										slideshowObj.trigger('prev.owl.carousel');

									}
								);

						//
						// Functions
						//

							// Change Slide

								function slideshowChangeSlide(index) {

									var calc = index + slideshowClonesItems;

									slideshowObj.trigger('to.owl.carousel', [index, slideshowSpeed, true]);
									slideshowChangePagination(calc);

								}

							// Change Pagination

								function slideshowChangePagination(index) {

									var calc = index - slideshowClonesItems;

									if (calc < 0) {

										var slideItemCountIndex = slideItemCount - 1;

										slideshowPaginationObj
											.children('li[data-count=' + slideItemCountIndex + ']')
											.addClass('-active');

									} else {

										slideshowPaginationObj
											.children('li[data-count=' + calc + ']')
											.addClass('-active');

									}

									// Add Ons - Start
									// Add Ons - End

								}

						//
						// Pagination
						//

							// Set First Pagination Element Active

								slideshowPaginationObj
									.children('li:first-child')
									.addClass('-active');

							// Click

								slideshowPaginationObj
									.children('li')
									.click(
										function(event){

											slideshowActiveCount = $(this).data('count');
											slideshowChangeSlide(slideshowActiveCount);
											event.preventDefault();

										}
									);

							// Change - Start

								slideshowObj
									.on(
										'change.owl.carousel',
										function(event) {

											slideshowPaginationObj
												.children('li')
												.removeClass('-active');

										}
									);

							// Change - Finished

								slideshowObj
									.on(
										'translate.owl.carousel',
										function(event) {

											slideshowActiveCount = event.item.index;
											slideshowChangePagination(slideshowActiveCount);

										}
									);

						//
						// Arrows
						//

							slideshowNext
								.click(
									function(event){
										slideshowObj.trigger('next.owl.carousel');
										event.preventDefault();
									}
								);

							slideshowPrev
								.click(
									function(event){
										slideshowObj.trigger('prev.owl.carousel');
										event.preventDefault();
									}
								);

						//
						// Addons
						//


					}

				);

		}


	}
}
