// ===============================
//
// Variables
//
// ===============================

	// Resize Width
	var windowResizeWidth = viewport.getWidth();

	// Breakpoints (Match _settings.scss)
	var breakpoint = {
		small: 0,
		medium: 640,
		large: 1024,
		xlarge: 1200,
		xxlarge: 1440
	}

	// States
	var state = {
		hidden: 'is-hidden',
		visible: 'is-visible',
		selected: 'is-selected',
		active: '-active',
		removed: 'is-removed',
		processing: 'is-processing',
		loading: 'is-loading'
	}

// ===============================
//
// Modules
//
// ===============================

	// Module

	var module = {
		variables: {
			var: null
		},
		init: function() {

		}
	}

	// Search

	var search = {
		init: function() {

			$('.js-search')
				.click(

					function(event) {

						$('.js-search-bar, .js-search')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Accordion

	var accordion = {
		variables: {
			var: null
		},
		init: function() {

			$('.js-accordion-item')
				.click(

					function(event) {

						var id = $(this).data('id');

						$('.js-accordion-item')
							.removeClass(state.active);

						$(this)
							.addClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Tab Content

	var tabContent = {
		variables: {
			var: null
		},
		init: function() {

			$('.js-tab-content-nav-item')
				.click(

					function(event) {

						var id = $(this).data('id');

						$('.js-tab-content-item, .js-tab-content-nav-item')
							.removeClass(state.active);

						$('.js-tab-content-item[data-id=' + id + '], .js-tab-content-nav-item[data-id=' + id + ']')
							.addClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// SVG

	var svg = {
		init: function() {

			$('.js-svg-convert').svgConvert();

		}
	}

	// Grid

	var grid = {
		init: function() {

			$('.js-grid')
				.waitForImages().done(

					function() {

						$('.js-grid')
							.packery(
								{
									itemSelector: '.js-grid-item',
								}
							);

					}

				);

		}
	}

	// Lightbox

	var lightbox = {
		init: function() {

			if ($('.js-lightbox-onload').length) {

				var delay = $('.js-lightbox-onload').data('lightbox-delay');

				setTimeout(
					function(){
						$.magnificPopup
							.open(
								{
									items: {
										src: '#' + $('.js-lightbox-onload').attr('id')
									},
									type: 'inline'
								}
							);
					},
					delay
				);

			}

			$('.js-lightbox-inline-open')
				.magnificPopup(
					{
						type:'inline',
						midClick: true
					}
				);

			$('.js-lightbox-video-open')
				.magnificPopup(
					{
						disableOn: 700,
						type: 'iframe',
						mainClass: 'mfp-fade',
						removalDelay: 160,
						preloader: false,
						fixedContentPos: false
					}
				);

		}
	}

	// Validation

	var validation = {
		selectors: {
			form: $('.js-form-validate')
		},
		init: function() {

			this.selectors.form
    			.shapeValidate({
	    			alerts: {
						required: '*',
						email: '*',
					},
    			});

		}
	}


	// Sticky Kit

	var sticky = {
		init: function() {

			$(".js-sticky")
				.stick_in_parent(
					{
						offset_top: 20,
						bottoming: true,
						recalc_every: 1
					}
				);

		}
	}

	// Scroll To

	var scrollTo = {
		init: function() {

			$('.js-scroll-to')
				.click(

					function(event) {

						var id = $(this).data('scroll-id');

						$('.js-scroll-to')
							.removeClass(state.active);

						$(this)
							.addClass(state.active);

						$('html, body')
							.stop()
							.animate(
								{
									'scrollTop': $('.js-scroll-target[data-scroll-id=' + id + ']').offset().top -40
						 		}, 600
						 	);

						event.preventDefault();

					}

				);

		}
	}

	// Product Variants

	var productVariantFilter = {
		selectors: {
			productVariant: $('.js-product-variant'),
			productVariantOption: $('.js-product-variant option'),
			productVariantGroup: $('.js-product-variant-group'),
			productPrice: $('.js-product-price .price-inc-vat .price'),
			productPriceExc: $('.js-product-price .price-exc-vat .price'),
			productSubmit: $('.js-product-submit')
		},
		init: function() {

			// Create Variables / Arrays

				var arrayGroup = [];
					arrayPrice = [],
					arrayPriceExc = [],
					arrayPriceSale = [],
					arrayPriceSaleExc = [],
					arrayCode = [],
					$options = this.selectors.productVariantOption;

			// Populate Arrays

				this.selectors.productVariantOption
					.each(
						function(i){

							arrayGroup[i] = $(this).data('variant-group');
							arrayPrice[i] = $(this).data('variant-price');
							arrayPriceExc[i] = $(this).data('variant-price-exc');
							arrayPriceSale[i] = $(this).data('variant-price-sale');
							arrayCode[i] = $options[i].outerHTML;

						}
					);

			// Change Variant Group Option

				this.selectors.productVariantGroup
					.change(

						function() {

							// Variables

								var productVariantGroupVal = $(this).children('option:selected').val(),
									productVariantGroupPrice = $(this).data('price'),
									productVariantGroupPriceExc = $(this).data('price-exc'),
									productVariantGroupPriceSale = $(this).data('price-sale');

							// Reset Prices

								// Price & Sale (if applicable)

									if ( productVariantGroupPrice != productVariantGroupPriceSale ) {

										//productVariantFilter.selectors.productPrice
										//	.html(productVariantGroupPriceSale + ' <div class="price-sale">' + productVariantGroupPrice + '</div>');

									}

								// Update Price only

									else {

										productVariantFilter.selectors.productPrice
											.html(productVariantGroupPrice);

										productVariantFilter.selectors.productPriceExc
											.html(productVariantGroupPriceExc);

									}

							// Disable product variant option

								if ( productVariantGroupVal == '' ) {

									productVariantFilter.selectors.productVariant
										.attr('disabled', 'disabled')
										.append('<option value="">Choose an Option</option>');

									productVariantFilter.selectors.productSubmit
										.attr('disabled', 'disabled');

									$('.js-slideshow').trigger('to.owl.carousel', [0 , 300]);
								}

							// Enable product variant option

								else {

									productVariantFilter.selectors.productVariant
										.removeAttr('disabled');
								}

							// Remove Options

							productVariantFilter.selectors.productVariant
								.children('option')
								.remove();

							// Add Defaults

								productVariantFilter.selectors.productVariant
									.append('<option value="">Choose an Option</option><option value="">----------------</option>');

							// Get options related to chosen Variant Group

								var arrayGroupLength = arrayGroup.length;

								for (var i = 0; i < arrayGroupLength; i++) {

									if (arrayGroup[i] == productVariantGroupVal) {

										productVariantFilter.selectors.productVariant
											.append(arrayCode[i]);

									}

								}


						}

					);

			// Change Variant option

				productVariantFilter.selectors.productVariant
					.change(

						function() {

							// Variables

								var variantVal = $(this).children('option:selected').val(),
									variantPrice = $(this).children('option:selected').data('variant-price'),
									variantPriceExc = $(this).children('option:selected').data('variant-price-exc'),
									variantPriceSale = $(this).children('option:selected').data('variant-price-sale'),
									variantPriceSaleExc = $(this).children('option:selected').data('variant-price-sale-exc'),
									variantImage = $(this).children('option:selected').data('variant-image');

							// Select Image

								if ( variantImage != undefined ) {

									var imagePos = $('.owl-item .product-image[data-image-id=' + variantImage + ']').parent('.owl-item').index();

									$('.js-slideshow').trigger('to.owl.carousel', [imagePos , 300]);

								}

							// Disable product variant option

								if ( variantVal == '' ) {

									productVariantFilter.selectors.productSubmit
										.attr('disabled', 'disabled');

								}

							// Enable product variant option

								else {

									productVariantFilter.selectors.productSubmit
										.removeAttr('disabled');

								}

							// Update Price & Sale (if applicable)

								if ( variantPrice != variantPriceSale ) {

								//	productVariantFilter.selectors.productPrice
								//		.html(variantPriceSale + ' <div class="price-sale">' + variantPrice + '</div>');

								}

							// Update Price only

								else {

									productVariantFilter.selectors.productPrice
										.html(variantPrice);

									productVariantFilter.selectors.productPriceExc
										.html(variantPriceExc);

								}

						}

					);

		}
	}

	// Form

	var form = {
		init: function() {

			// Checkbox

			$('.js-checkbox-item')
				.click(

					function(event) {

						if ( $(this).hasClass(state.active) ) {

							$(this)
								.removeClass(state.active);

							$(this)
								.children('input')
								.prop('checked', false);

						}

						else {

							$(this)
								.addClass(state.active)
								.siblings('.js-checkbox-item')
								.removeClass(state.active);

							$(this)
								.children('input')
								.prop('checked', true);

							$(this)
								.siblings('.js-checkbox-item')
								.children('input')
								.prop('checked', false);

						}

						$('.js-form')
							.submit();

						event.preventDefault();

					}

				);

		}
	}

	// Map

	var map = {
		init: function() {

			if ( $('#map').length != 0 ) {

				initMap();

			}
		}
	}

	// Sidebar

	var sidebar = {
		init: function() {

			$('.js-sidebar-toggle')
				.click(

					function(event) {

						$('.js-sidebar-toggle, .js-sidebar-container')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Burger
	var burger = {
		init: function() {

			$('.js-site-header-burger')
				.click(

					function(event) {

						$('.js-site-header-burger, .js-site-header-navigation, .js-site-header')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Lazy

	var lazy = {
		init: function() {


			var lazyLoadInstance = new LazyLoad({ elements_selector: ".js-lazy-load" });

		}
	}


	var formSubmit = {
		init: function() {

			$('.js-order-by-select')
				.change(

					function() {

						var val = $(this).val();

						$('.js-order-by-input').val(val);

						$('.js-form')
							.submit();

					}

				);

		}
	}

// ===============================
//
// Initialise
//
// ===============================

	// Ready
	$(document)
		.ready(
			function(){
				environment.init();
				viewport.init();
				slideshow.init();
				scrollTo.init();
				sticky.init();
				validation.init();
				lightbox.init();
				grid.init();
				svg.init();
				productVariantFilter.init();
				tabContent.init();
				form.init();
				accordion.init();
				map.init();
				sidebar.init();
				burger.init();
				search.init();
				lazy.init();
				formSubmit.init();
			}
		);

	// Resize
	$(window)
		.resize(
			function(){

				if ($(window).width() != windowResizeWidth) {
					windowResizeWidth = viewport.getWidth(); // Required for Mobile Safari & Chrome
				}
			}
		);

	// Scroll
	$(window)
		.scroll(
			function(){
			}
		);
